
import { defineComponent, PropType } from "vue";
import {
  ButtonComponent,
  copy,
  STATUS_REPORTING_FORM_SELECT_VALUES,
  TABLE_ICONS,
  TSelectComponent,
} from "table";
import { reportHelpers } from "@/modules/registration-reports/common/helpers";
import { isEqual } from "@/common/helpers/isEqual";
import { AnalyticalReportFilter } from "@/modules/analytical-reports/common/types";

export default defineComponent({
  name: "AnalyticalReportsFilter",
  components: {
    ButtonComponent,
    TSelectComponent,
  },
  props: {
    filterData: {
      type: Object as PropType<AnalyticalReportFilter>,
      required: true,
    },
    currentCount: Number,
  },
  emits: ["on-filter", "update:filter"],
  setup() {
    return {
      STATUS_REPORTING_FORM_SELECT_VALUES,
    };
  },
  data() {
    return {
      TABLE_ICONS,
      filterDataNew: copy(this.filterData),
    };
  },
  watch: {
    // filterData() {
    //   if (isEqual(this.filterDataNew, this.filterData)) {
    //     return;
    //   }
    //
    //   this.filterDataNew = cloneDeep(this.filterData);
    // },

    filterDataNew: {
      handler() {
        if (isEqual(this.filterDataNew, this.filterData)) {
          return;
        }

        this.$emit("update:filter", this.filterDataNew);
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    onReset() {
      this.filterDataNew = reportHelpers.getInitFilterData();
      this.$emit("update:filter", this.filterDataNew);
      this.$emit("on-filter");
    },
  },
});
