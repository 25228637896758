import { cloneDeep } from "table";
import { AnalyticalReportFilter } from "@/modules/analytical-reports/common/types";

const initFilterData = {} as AnalyticalReportFilter;

const getInitFilterData = () => cloneDeep(initFilterData);

export const analyticalReportHelpers = {
  getInitFilterData,
};
