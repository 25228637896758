
import { defineComponent } from "vue";
import {
  ApiHelper,
  ButtonComponent,
  ContextMenuOption,
  ContextMenuService,
  dateFormat,
  Dictionary,
  HttpService,
  Logger,
  NotificationsService,
  PanelFilters,
  PanelFiltersSet,
  ScreenSpinner,
  TABLE_ICONS,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableMultiHeaders,
} from "table";
import AnalyticalReportsCreateReport from "@/modules/analytical-reports/components/AnalyticalReportsCreateReport.vue";
import ApiAReportsService from "@/modules/analytical-reports/services/ApiAReportsService/ApiAReportsService";
import { AReportDataDto } from "@/modules/analytical-reports/common/types";
import AnalyticalReportsFilter from "@/modules/analytical-reports/components/AnalyticalReportsFilter.vue";
import { analyticalReportHelpers } from "@/modules/analytical-reports/common/helpers";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import AppUsersService from "@/services/AppUsersService/AppUsersService";
import { UserHelper } from "@/common/helpers/UserHelper";
import {
  getStatusAReportName,
  STATUS_AREPORT_NAME,
} from "@/modules/analytical-reports/services/ApiAReportsService/common/types/StatusAReport";
import { AReportsExtraInfo } from "@/modules/analytical-reports/services/ApiAReportsService/common/types/AReportsExtraInfo";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { useSaveSettingsInTableDataSet } from "@/services/UserSettingsService/helpers";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";

export default defineComponent({
  name: "AnalyticalReportsList",
  components: {
    AnalyticalReportsFilter,
    AnalyticalReportsCreateReport,
    TableComponent,
    TableMultiHeaders,
    ButtonComponent,
    ScreenSpinner,
    PanelFilters,
  },
  props: {
    aReportListUrl: String,
  },
  setup() {
    return {
      TABLE_ICONS,
      dateFormat,
      window,
      UserHelper,
      getStatusAReportName,
    };
  },
  data() {
    return {
      open: {
        createReportForm: false,
        filter: false,
      },
      list: null as null | ApiListWrapper<AReportDataDto>,
      table: null as null | {
        tableDataSet: TableDataSet;
        factory: TableDataSetFactory;
      },
      filterData: analyticalReportHelpers.getInitFilterData(),
      filterDataActual: analyticalReportHelpers.getInitFilterData(),
      listFormFiltered: undefined as undefined | AReportDataDto[],
      error: false,
      usersDict: null as Dictionary<ApiVocUserDto> | null,
      aReportsExtraInfo: null as AReportsExtraInfo | null,
    };
  },
  async created() {
    this.usersDict = await AppUsersService.getUsersDict();
    await ApiHelper.wrapNotifyError(async () => {
      this.aReportsExtraInfo = (await ApiAReportsService.getExtraInfo()).json;
    });
    await this.initList();
    this.initFilter(true);
  },
  updated() {},
  beforeUnmount() {},
  computed: {
    listFormFilteredActual(): AReportDataDto[] | undefined {
      return this.list?.result.filter((listValue: AReportDataDto) => {
        const { areports_type } = this.filterDataActual;
        if (areports_type?.length) {
          if (
            !areports_type.some(
              (areports_type) => listValue.areport_name === areports_type.value,
            )
          ) {
            return false;
          }
        }

        return true;
      });
    },
  },
  methods: {
    async initList() {
      try {
        this.list = this.aReportListUrl
          ? (
              await HttpService.get<ApiListWrapper<AReportDataDto>>(
                this.aReportListUrl,
              )
            ).json
          : (await ApiAReportsService.getAReportDataList()).json;
        const uniqId = "analytical-reports";
        const filtersSaveModule = new PanelFiltersSaveModule(uniqId);
        await filtersSaveModule.init();
        const filtersSet = new PanelFiltersSet(filtersSaveModule);
        filtersSet.subject.subscribe((data) =>
          applyPanelFiltersData(this.table!.tableDataSet as TableDataSet, data),
        );
        const factory = new TableDataSetFactory({
          headers: [
            "areport_name",
            "areport_creator",
            "areport_state",
            "areport_ts",
            "context_menu",
          ],
          modelDtoArray: [
            {
              caption: "Название",
              field: "areport_name",
              type: "slot",
              slot: "areport_name",
              width: "500px",
            },
            {
              caption: "Автор",
              field: "areport_creator",
              type: "users",
              width: "200px",
            },
            {
              caption: "Статус",
              field: "areport_state",
              type: "areport_state",
              width: "130px",
            },
            {
              caption: "Дата создания",
              field: "areport_ts",
              type: "date",
              width: "105px",
            },
            {
              caption: "",
              field: "context_menu",
              type: "slot",
              slot: "context_menu",
              width: "50px",
            },
          ],
          types: {
            slot: {
              base_type: "SLOT",
            },
            users: {
              base_type: "DICT",
              data_source: await UserHelper.getTableUsersDict(),
            },
            areport_state: {
              base_type: "DICT",
              data_source: STATUS_AREPORT_NAME,
            },
            date_time: {
              base_type: "DATE",
              display: {
                date_format: "DD.MM.YYYY HH:MM",
              },
            },
            date: {
              base_type: "DATE",
            },
          },
          rows: this.list.result,
          edit: {},
          uniqId,
          tableName: "default",
          tableDataSetOptions: {
            display: {
              rowIndexCol: false,
            },
            filtersSet,
          },
        });
        const tableDataSet = await factory.create();
        await useSaveSettingsInTableDataSet(tableDataSet);
        this.table = {
          factory,
          tableDataSet: tableDataSet,
        };
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при загрузке списка аналитических отчётов",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        this.error = true;
      }
    },
    initFilter(takeAwayFromUrl: boolean) {
      if (!takeAwayFromUrl) {
        this.filterData = this.filterDataActual;
        return;
      }

      const initFilter = this.$route.query.filter as string | undefined;
      if (initFilter) {
        try {
          const filter = JSON.parse(initFilter);
          this.filterDataActual = filter;
          this.filterData = this.filterDataActual;
          this.open.filter = true;
        } catch (e: any) {
          return;
        }
      }
    },
    setFilterDataInUrl() {
      let query: any = Object.assign({}, this.$route.query);
      query.filter = JSON.stringify(this.filterData);
      this.$router.replace({ query });
    },

    getOptionsExport(aReportListValueDto: AReportDataDto): ContextMenuOption[] {
      return [
        {
          text: "Экспорт в .xlsx",
          click: () =>
            ApiAReportsService.exportToXlsx(aReportListValueDto.areport_id),
          icon: TABLE_ICONS.download,
        },
      ];
    },

    remove(aReportListValueDto: AReportDataDto) {
      ApiHelper.wrapNotifyError(
        ApiAReportsService.removeAReport(aReportListValueDto.areport_id),
        {
          resolve: () => {
            this.initList();
            this.initFilter(false);
          },
        },
      );
    },
    openContextMenu(event: PointerEvent, aReportDataDto: AReportDataDto) {
      ContextMenuService.send({
        event: event as any,
        options: [
          ...this.getOptionsExport(aReportDataDto),
          {
            text: "Удалить",
            click: () => {
              this.remove(aReportDataDto);
            },
            icon: TABLE_ICONS.trashCanOutline,
          },
        ],
      });
    },
  },
});
